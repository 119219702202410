import React, { type FC, type ReactNode, useEffect, useRef } from 'react';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';

import { fg } from '@confluence/feature-gating';

import { useCustomSitesExtensions } from './useCustomSitesExtensions';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const CardSelectedToEditWrapperStyles = styled.div<{
	isSelectedToEdit: boolean;
	isSelectedToRemove: boolean;
}>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'& div[data-smart-link-container]': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-dynamic-styles
		...({ isSelectedToEdit }) =>
			isSelectedToEdit && {
				outline: `2px solid ${token('color.border.focused')}`,
			},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-dynamic-styles
		...({ isSelectedToRemove }) =>
			isSelectedToRemove && {
				outline: `2px solid ${token('color.border.danger')}`,
			},
	},

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'& div[data-smart-link-container]::before': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-dynamic-styles
		...({ isSelectedToRemove }) =>
			isSelectedToRemove && {
				content: '',
				position: 'absolute',
				top: 0,
				left: 0,
				right: 0,
				bottom: 0,
				zIndex: 1,
				opacity: 0.5,
				backgroundColor: `${token('color.background.danger')}`,
			},
	},
});

type CardSelectedToEditWrapperProps = {
	cardId: string;
	children: ReactNode;
};

export const CardSelectedToEditWrapper: FC<CardSelectedToEditWrapperProps> = ({
	cardId,
	children,
}) => {
	const cardRef = useRef<HTMLDivElement>(null);
	const [{ selectedToEditCardId, selectedToRemoveCardId }, { setSelectedToEditCardId }] =
		useCustomSitesExtensions();

	useEffect(() => {
		if (selectedToEditCardId && selectedToEditCardId === cardId && cardRef.current) {
			cardRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
		}
	}, [cardId, selectedToEditCardId, cardRef]);

	if (!fg('company_hub_manual_cards_refinement')) {
		return <>{children}</>;
	}

	return (
		<CardSelectedToEditWrapperStyles
			ref={cardRef}
			isSelectedToEdit={selectedToEditCardId === cardId}
			isSelectedToRemove={selectedToRemoveCardId === cardId}
			onClick={() => setSelectedToEditCardId(cardId)}
		>
			{children}
		</CardSelectedToEditWrapperStyles>
	);
};
