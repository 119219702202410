import React, { useEffect } from 'react';
import { defineMessages } from 'react-intl-next';

import { CardSizes } from '../../../linkCardsTypes';
import restrictedContentImage from '../../../../assets/RestrictedContent.svg';

import { SmallErrorState } from './SmallErrorState';
import { HeroErrorState } from './HeroErrorState';
import { ErrorState } from './ErrorState';
import type { CardErrorStateAnalyticsProps, CommonCardErrorStateProps } from './CardErrorState';

export type RestrictedContentErrorStateProps = {
	link?: string;
} & CardErrorStateAnalyticsProps &
	CommonCardErrorStateProps;

export const i18n = defineMessages({
	restrictedContent: {
		id: 'custom-sites-extensions.link-cards-content.card-components.error-states.restricted-content.header',
		defaultMessage: 'Restricted content',
		description:
			'Heading message shown when a URL we are trying to render as a smart card is restricted from user access',
	},
	restrictedContentDescription: {
		id: 'custom-sites-extensions.link-cards-content.card-components.error-states.restricted-content.description',
		defaultMessage: 'You’ll need to request access or try another account to view this preview',
		description:
			'Describes possible action when a URL we are trying to render as a smart card is restricted from user access',
	},
	restrictedContentButtonLabel: {
		id: 'custom-sites-extensions.link-cards-content.card-components.error-states.restricted-content.button.label',
		defaultMessage: 'Request access',
		description:
			'Label for button that allows user to access restricted content page to request access',
	},
});

export const RestrictedContent = ({
	cardId,
	cardSize,
	cardHeight,
	link,
	isInViewMode,
	errorStatus,
	createAnalyticsEvent,
	analyticsSource,
	extensionType,
	isCardSelected,
	isExtraSmallCard,
	ariaLabel,
	isVerticalHeroCard,
	handleWidthObserverSetWidth,
}: RestrictedContentErrorStateProps) => {
	useEffect(() => {
		createAnalyticsEvent?.({
			type: 'sendOperationalEvent',
			data: {
				source: analyticsSource,
				action: 'error',
				actionSubject: 'restricted',
				attributes: {
					extensionType,
					error: errorStatus,
					isInViewMode,
				},
			},
		}).fire();
	}, [createAnalyticsEvent, errorStatus, isInViewMode, analyticsSource, extensionType]);

	switch (cardSize) {
		case CardSizes.SMALL:
		case CardSizes.EXTRA_SMALL:
			return (
				<SmallErrorState
					cardId={cardId}
					link={link}
					image={restrictedContentImage}
					heading={i18n.restrictedContent}
					subheading={i18n.restrictedContentButtonLabel}
					cardHeight={cardHeight}
					isInViewMode={isInViewMode}
					hideLink={cardSize === CardSizes.EXTRA_SMALL}
					isCardSelected={isCardSelected}
					isExtraSmallCard={isExtraSmallCard}
				/>
			);
		case CardSizes.HERO:
			return (
				<HeroErrorState
					cardId={cardId}
					link={link}
					image={restrictedContentImage}
					heading={i18n.restrictedContent}
					description={i18n.restrictedContentDescription}
					subheading={i18n.restrictedContentButtonLabel}
					cardHeight={cardHeight}
					isInViewMode={isInViewMode}
					ariaLabel={ariaLabel}
					isVerticalHeroCard={isVerticalHeroCard}
					handleWidthObserverSetWidth={handleWidthObserverSetWidth}
				/>
			);
		default:
			return (
				<ErrorState
					cardId={cardId}
					link={link}
					heading={i18n.restrictedContent}
					description={i18n.restrictedContentDescription}
					primaryAction={i18n.restrictedContentButtonLabel}
					image={restrictedContentImage}
					cardHeight={cardHeight}
					isInViewMode={isInViewMode}
				/>
			);
	}
};
