import React from 'react';
import { FormattedMessage } from 'react-intl-next';

import {
	CommonErrorStateWrapper,
	SmallErrorStateImage,
	SmallErrorStateTextWrapper,
	SmallErrorStateHeading,
	ErrorStateSmallLink,
} from './errorStateStyles';

type SmallErrorStateProps = {
	cardId: string;
	image: SVGElement;
	link?: string;
	heading: Record<string, React.ReactNode>;
	subheading?: Record<string, React.ReactNode>;
	cardHeight: number;
	isInViewMode: boolean;
	hideLink?: boolean;
	isCardSelected?: boolean;
	isExtraSmallCard?: boolean;
};

export const SmallErrorState = ({
	cardId,
	image,
	heading,
	subheading,
	cardHeight,
	link,
	isInViewMode,
	hideLink,
	isCardSelected,
	isExtraSmallCard,
}: SmallErrorStateProps) => {
	return (
		<CommonErrorStateWrapper
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			className="link-card-small-error-state"
			id={cardId}
			cardHeight={cardHeight}
			isInViewMode={isInViewMode}
			isCardSelected={isCardSelected}
			isExtraSmallCard={isExtraSmallCard}
		>
			<SmallErrorStateImage src={image} />
			<SmallErrorStateTextWrapper>
				<SmallErrorStateHeading>
					<FormattedMessage {...heading} />
				</SmallErrorStateHeading>
				{!!subheading && !hideLink && (
					<ErrorStateSmallLink href={link} target="_blank">
						<FormattedMessage {...subheading} />
					</ErrorStateSmallLink>
				)}
			</SmallErrorStateTextWrapper>
		</CommonErrorStateWrapper>
	);
};
