/* eslint-disable @atlaskit/design-system/ensure-design-token-usage/preview */
import { styled, css as compiledCss } from '@compiled/react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css } from '@emotion/react';

import { N40, N200, N500, B400 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { MediaPlacement } from '@atlaskit/smart-card';

import { CardSizes } from '../../linkCardsTypes';
import { cardsDimensions } from '../GridCardWrapper';

export const getCustomImageUploadStyles = (placement?: MediaPlacement, isHeroCard?: boolean) => {
	if (placement === MediaPlacement.Left) {
		const containerPadding = token('space.0', '0px');
		return css({
			position: 'absolute',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			width: isHeroCard ? 'var(--preview-block-width)' : '72px',
			top: containerPadding,
			bottom: containerPadding,
			left: containerPadding,
		});
	} else {
		return css({
			marginLeft: 'calc(var(--container-gap-left) * -1)',
			marginRight: 'calc(var(--container-gap-right) * -1)',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
			'&:first-of-type': {
				marginTop: 'calc(var(--container-padding) * -1)',
			},
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
			'&:last-of-type': {
				marginBottom: 'calc(var(--container-padding) * -1)',
			},
		});
	}
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const floatingEmojiStyles = css({
	position: 'relative',
	top: token('space.negative.150', '-12px'),
	bottom: token('space.025', '2px'),
	height: '0px',
	overflow: 'visible',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const extraSmallCardEmojiStyles = css({
	marginRight: token('space.100', '8px'),
	flexShrink: 0,
	// emojis are being cut off
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> span': {
		marginTop: token('space.025', '2px'),
		marginBottom: token('space.025', '2px'),
	},
});

export const getTitleBlockStyles = ({
	isEmptyCard = false,
	isSmallCard = false,
	isDisabledTextColor = false,
	isExtraSmallCard = false,
	isHeroCard = false,
	isUnauthorized = false,
	disablePointerEvents = false,
} = {}) =>
	css({
		marginTop:
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			isSmallCard || isExtraSmallCard || isHeroCard
				? token('space.0', '0px')
				: token('space.100', '8px'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'[data-smart-element-link]': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			...(isUnauthorized ? { display: 'none' } : {}),
			fontWeight: 500,
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			color: isDisabledTextColor ? token('color.text.subtlest') : token('color.text'),
			letterSpacing: '-0.01px',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			flexShrink: isExtraSmallCard ? 1 : 0,
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		'>span:first-child': isUnauthorized ? { flex: 0 } : {},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		...(isUnauthorized ? { gap: 0 } : {}),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
		'[data-smart-element-group] > span:first-child': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values
			...(!isUnauthorized && {
				textAlign: 'left',
				'-webkit-line-clamp': '2',
				color: token('color.text'),
			}),
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'a, a:visited, a:focus, a:hover, a:active': {
			textDecoration: 'none',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			pointerEvents: disablePointerEvents || isEmptyCard ? 'none' : 'auto',
		},
	});

export const emptyDescriptionBlockStyles = ({ isDisabledTextColor = false }) =>
	css({
		marginTop: `${token('space.negative.200', '-16px')}`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'[data-smart-element-text]': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			color: isDisabledTextColor ? token('color.text.subtlest') : token('color.text', N500),
		},
	});

const commonSmartlinkOverrideStyles = compiledCss({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& div[data-smart-link-container]': {
		boxSizing: 'border-box',

		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
		"& div[data-smart-block]:first-of-type:not(:has(span[data-vc='emoji-component'])):has(img)": {
			height: '100%',
			flexShrink: 1,
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			'& div[data-smart-element-media]': {
				height: '100%',
			},
		},
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const CommonCardWrapperStyles = styled.div<{
	isInViewMode: boolean;
	isEmptyCard?: boolean;
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
}>(commonSmartlinkOverrideStyles, {
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& div[data-smart-link-container]': {
		overflow: 'hidden',
		boxShadow: token(
			'elevation.shadow.raised',
			'0 0 1px 0 rgba(9, 30, 66, 0.31), 0 1px 1px 0 rgba(9, 30, 66, 0.25)',
		),
		border: 'none',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		...({ isInViewMode, isEmptyCard }) =>
			isInViewMode &&
			!isEmptyCard && {
				'&:hover': {
					boxShadow: `0px 5px 12px 0px ${token('color.border', N40)}`,
					cursor: 'pointer',
				},
			},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
		'& div[data-smart-block]:nth-child(n+3)': {
			flexShrink: 0,
		},
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const CommonCarouselWrapperStyles = styled.div<{
	isCardSelected?: boolean;
	isHeroCard?: boolean;
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
}>(commonSmartlinkOverrideStyles, {
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& div[data-smart-link-container]': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		border: ({ isCardSelected }) =>
			isCardSelected
				? `${token('border.width', '1px')} ${token('color.border.selected', B400)} solid`
				: `${token('border.width', '1px')} ${token('color.border', N200)} solid`,

		'&:hover': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
			cursor: ({ isHeroCard }) => (isHeroCard ? 'cursor' : 'pointer'),
		},
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const EmptyAndSmallCardWrapper = styled.div<{
	cardHeight: number;
	size: string;
	isPublishDateShown?: boolean;
	isEmptyCard?: boolean;
	hasNoImage?: boolean;
}>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& div[data-smart-link-container]': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		...({ size, hasNoImage }) =>
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			size === CardSizes.SMALL &&
			!hasNoImage && {
				paddingLeft: token('space.1000', '80px'),
			},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		height: ({ cardHeight }) => `${cardHeight}px`,
		justifyContent: 'center',
	},

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& div[data-smart-element-group]': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
		display: 'block !important',
		overflow: 'visible',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		...({ size }) =>
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			size === CardSizes.SMALL && {
				marginLeft: token('space.100', '8px'),
			},

		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'& div[data-smart-element-group]': {
			marginLeft: token('space.0', '0px'),
		},

		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'> span[data-smart-element-date-time]': {
			display: 'flex',
		},

		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		"span[data-smart-element='AuthorGroup'] > ul": {
			paddingLeft: token('space.0', '0px'),
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			'& > li::before': {
				content: '',
			},
		},
	},

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& div[data-smart-element-media]': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		width: ({ size }) => (size === CardSizes.SMALL ? '72px' : undefined),
	},

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	"& div[data-smart-block]:has(a[data-smart-element='Title'])": {
		gap: token('space.0', '0px'),
		overflow: 'visible',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		"& a[data-smart-element='Title']": {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
			'-webkit-line-clamp': ({ hasNoImage }) => (hasNoImage ? '3' : '2'),
		},
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const MediumLargeCardWrapper = styled.div<{
	cardHeight: number;
	titleHeight?: number;
	isLargeCard?: boolean;
	hasNoImage?: boolean;
}>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& div[data-smart-link-container]': {
		// TODO Remove this custom styling when CTE-2894 is done
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		height: ({ cardHeight }) => `${cardHeight}px`,
		justifyContent: 'center',
		gap: token('space.100', '10px'),

		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
		"div[data-smart-block]:has(span[data-smart-element='ModifiedOn'])": {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
			height: ({ titleHeight, isLargeCard }) =>
				isLargeCard
					? token('space.400', '32px')
					: titleHeight && titleHeight < 32
						? token('space.300', '24px')
						: token('space.400', '32px'),
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors,
		"& a[data-smart-element='Title']": {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
			'-webkit-line-clamp': ({ hasNoImage }) => (hasNoImage ? '3' : '2'),
		},
	},

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& div[data-smart-element-group]': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
		display: 'block !important',
		overflow: 'visible',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'>span[data-smart-element-date-time]': {
			display: 'flex',
		},

		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		"span[data-smart-element='AuthorGroup'] > ul": {
			marginInlineEnd: token('space.100', '8px'),
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			'& > li::before': {
				content: '',
			},
		},
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ExtraSmallCardWrapper = styled.div<{
	cardHeight: number;
	minWidth: number;
	isPublishDateShown?: boolean;
	isEmptyCard?: boolean;
	hasEmoji?: boolean;
	maxWidth: number;
	isCardSelected?: boolean;
}>({
	'--preview-block-width': '72px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& div[data-smart-link-container]': {
		overflow: 'hidden',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		...({ hasEmoji }) =>
			hasEmoji && {
				paddingLeft: `calc(var(--preview-block-width) + ${token('space.200', '16px')})`,
			},
		flexShrink: 1,
		flexDirection: 'row',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		height: ({ cardHeight }) => `${cardHeight}px`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		minWidth: ({ minWidth }) => `${minWidth}px`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		maxWidth: ({ maxWidth }) => `${maxWidth}px`,
		marginRight: '0px',
		marginLeft: '0px',
		justifyContent: 'flex-start',
		alignItems: 'center',
		cursor: 'pointer',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const HeroCardWrapper = styled.div<{
	cardHeight: number;
	isPublishDateShown?: boolean;
	isEmptyCard?: boolean;
	isVerticalLayout: boolean;
}>({
	position: 'relative',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	minWidth: `${cardsDimensions[CardSizes.HERO].minWidth}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& div[data-smart-link-container]': {
		'--preview-block-width': 'calc(100% - 256px)',
		borderRadius: token('border.radius.100', '8px'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		paddingLeft: ({ isVerticalLayout }) =>
			!isVerticalLayout
				? `calc(var(--preview-block-width) + ${token('space.400', '32px')})`
				: token('space.300', '24px'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		height: ({ cardHeight }) => `${cardHeight}px`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		...({ isEmptyCard }) =>
			isEmptyCard && {
				paddingBlock: token('space.200', '18px'),
			},
		gap: token('space.100', '8px'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		...({ isVerticalLayout }) =>
			isVerticalLayout && {
				display: 'flex',
				paddingBlock: token('space.300', '24px'),
				// Fixes description block cut-off issue
				'& div[data-smart-block]:nth-child(n+3)': {
					flexShrink: 0,
				},
			},
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'div[smart-footer-block-resolved-view]': {
		flexGrow: 1,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'> div': {
			alignSelf: 'flex-end',
		},
	},
});
