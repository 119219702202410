import { useCallback, useEffect, useState } from 'react';
import type { JsonLd } from 'json-ld-types';

import { Client } from '@atlaskit/smart-card';

type useSmartCardsEmojiResult = {
	emojiId: string | null;
};

type ResolvedDataObject = {
	data: JsonLd.Data.BaseData;
};

const sanitizeEmojiValue = (value: string | undefined) =>
	(value && value.replace(/['"]+/g, '').replace(/null/, '')) || '';

export const useSmartCardEmoji = (link: string): useSmartCardsEmojiResult => {
	const [emojiId, setEmojiId] = useState<string>();

	const getEmoji = useCallback(async () => {
		const client = new Client();
		const queriedEmojiObject = (await client.fetchData(link)) as ResolvedDataObject;

		setEmojiId(sanitizeEmojiValue(queriedEmojiObject.data['atlassian:titlePrefix']?.text));
	}, [link]);

	useEffect(() => {
		if (!!link) void getEmoji();
	}, [link, getEmoji]);

	return { emojiId: !!emojiId ? emojiId : null };
};
