import { useCallback, useMemo, useState } from 'react';

import type { Card } from '../linkCardsTypes';

export type OnCardSucceeded = (cardId: string) => void;
export type OnCardFailed = (error: Error) => void;
export const useCardsCombinedExperienceState = (cards: Card[]) => {
	const cardIdSet = useMemo(() => new Set(cards.map((card) => card.cardId)), [cards]);

	const [areAllCardsLoaded, setAreAllCardsLoaded] = useState(false);
	const [cardFailureError, setCardFailureError] = useState<Error | null>(null);

	const onCardFailed: OnCardFailed = useCallback((error) => {
		setCardFailureError(error);
	}, []);

	const onCardSucceeded: OnCardSucceeded = useCallback(
		(cardId: string) => {
			cardIdSet.delete(cardId);

			if (cardIdSet.size === 0) {
				setAreAllCardsLoaded(true);
			}
		},
		[cardIdSet],
	);

	return useMemo(
		() => ({
			areAllCardsLoaded,
			cardFailureError,
			onCardSucceeded,
			onCardFailed,
		}),
		[areAllCardsLoaded, cardFailureError, onCardSucceeded, onCardFailed],
	);
};
