import React from 'react';
import { styled } from '@compiled/react';

import { WidthObserver } from '@atlaskit/width-detector';
import { token } from '@atlaskit/tokens';

import { CardSizes } from '../linkCardsTypes';

type GridCardWrapperProps = React.PropsWithChildren<{
	isFirstRowFull: boolean;
	cardWidthOnFullRow: number;
	cardSize: CardSizes;
	watchWidth?: React.Dispatch<React.SetStateAction<number>>;
	testId?: string;
}>;

type CardWrapperProps = {
	isFirstRowFull: boolean;
	cardWidthOnFullRow: number;
	cardMinWidth: number;
	cardMaxWidth: number;
};

type CardDimensions = {
	minWidth: number;
	maxWidth: number;
	height: number;
};

type CardsDimensions = {
	[key in CardSizes]: CardDimensions;
};

export const cardsDimensions: CardsDimensions = {
	[CardSizes.EXTRA_SMALL]: {
		minWidth: 244,
		maxWidth: 250,
		height: 72,
	},
	[CardSizes.SMALL]: {
		minWidth: 228,
		maxWidth: 380,
		height: 72,
	},
	[CardSizes.MEDIUM]: {
		minWidth: 240,
		maxWidth: 308,
		height: 312,
	},
	[CardSizes.LARGE]: {
		minWidth: 358,
		maxWidth: 640,
		height: 312,
	},
	[CardSizes.HERO]: {
		minWidth: 334,
		maxWidth: 640,
		height: 350,
	},
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CardWrapper = styled.div<CardWrapperProps>({
	flex: 1,
	paddingBlock: token('space.025', '2px'),
	alignSelf: 'center',
	position: 'relative',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	...({ isFirstRowFull, cardWidthOnFullRow, cardMinWidth, cardMaxWidth }) => ({
		minWidth: isFirstRowFull ? `${cardWidthOnFullRow}px` : `${cardMinWidth}px`,
		maxWidth: isFirstRowFull ? `${cardWidthOnFullRow}px` : `${cardMaxWidth}px`,
	}),
});

export const GridCardWrapper = ({
	isFirstRowFull,
	cardWidthOnFullRow,
	cardSize,
	watchWidth,
	children,
	testId = 'card-wrapper',
}: GridCardWrapperProps) => (
	<CardWrapper
		data-testid={testId}
		isFirstRowFull={isFirstRowFull}
		cardWidthOnFullRow={cardWidthOnFullRow}
		cardMinWidth={cardsDimensions[cardSize].minWidth}
		cardMaxWidth={cardsDimensions[cardSize].maxWidth}
	>
		{children}
		{watchWidth && <WidthObserver setWidth={watchWidth} offscreen />}
	</CardWrapper>
);
