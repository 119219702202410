import React from 'react';
import type { FC } from 'react';
import { styled } from '@compiled/react';

import { LazyEmojiComponentLoader } from '@confluence/emoji-title';

const LINK_CARD_EMOJI_SIZE = 40;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EmojiWrapper = styled.span({
	display: 'flex',
	justifyContent: 'center',
	maxWidth: `${LINK_CARD_EMOJI_SIZE}px`,
});

export const EmojiRenderer: FC<{ emojiId: string | null; height?: number }> = ({
	emojiId,
	height = LINK_CARD_EMOJI_SIZE,
}) =>
	!!emojiId ? (
		<LazyEmojiComponentLoader
			emoji={emojiId}
			height={height}
			wrapper={EmojiWrapper}
			context="linkCard"
			renderResourcedEmoji
		/>
	) : null;
