import React from 'react';
import { styled } from '@compiled/react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl-next';

import { MediaImage } from '@atlaskit/media-image';
import WarningIcon from '@atlaskit/icon/glyph/warning';
import { token } from '@atlaskit/tokens';
import { Box, xcss } from '@atlaskit/primitives';

import type { MediaConfigType } from '../../linkCardsTypes';

export const i18n = defineMessages({
	imageFailedMessage: {
		id: 'custom-sites-extensions.link-cards.image-failed-to-load',
		defaultMessage: 'Image failed to load',
		description: "Message shown when the extensions's uploaded image failed to load",
	},
	warningIcon: {
		id: 'custom-sites-extensions.link-cards.warning',
		defaultMessage: 'Warning icon for image failed to load',
		description:
			"Label text for a warning icon that's shown when the extensions's uploaded image failed to load",
	},
});

const errorWrapperStyles = xcss({
	display: 'flex',
	flexDirection: 'column',
	width: '100%',
	height: '100%',
	justifyContent: 'center',
	alignItems: 'center',
	background: token('color.background.accent.gray.subtlest'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ImageWrapper = styled.img({
	height: '100%',
	width: '100%',
	aspectRatio: 'unset',
	objectFit: 'cover',
});

const uploadingImageStyles = xcss({
	width: '100%',
	height: '100%',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	background: token('color.background.accent.gray.subtlest'),
});

type CustomImageUploadProps = {
	imageSrc?: string;
	mediaConfig?: MediaConfigType;
	imageAltText?: string;
	shouldShowErrorMessage?: boolean;
};

export const CustomImageUpload = ({
	imageSrc,
	mediaConfig,
	imageAltText,
	shouldShowErrorMessage = false,
}: CustomImageUploadProps) => {
	const intl = useIntl();
	if (!mediaConfig) {
		return null;
	}
	return (
		<MediaImage
			identifier={{
				mediaItemType: 'file',
				id: String(imageSrc),
				collectionName: mediaConfig?.collection,
			}}
			//force rerender image on id change until https://product-fabric.atlassian.net/browse/CXP-2899 is fixed
			key={imageSrc}
			mediaClientConfig={{
				authProvider: mediaConfig?.authProvider,
			}}
			apiConfig={{
				allowAnimated: true,
				mode: 'fit' as const,
				upscale: false,
			}}
		>
			{({ data, loading, error }) => {
				if (loading) {
					return <Box testId="custom-image-upload-loading" xcss={uploadingImageStyles} />;
				}
				if (!data || !data?.src) {
					return null;
				}
				if (error) {
					return (
						<Box xcss={errorWrapperStyles} testId="custom-image-upload-error">
							<WarningIcon
								label={intl.formatMessage(i18n.warningIcon)}
								size="large"
								primaryColor={token('color.icon.subtle')}
							/>
							{shouldShowErrorMessage && <FormattedMessage {...i18n.imageFailedMessage} />}
						</Box>
					);
				}
				return (
					<ImageWrapper src={data?.src} alt={imageAltText} data-testid="custom-image-upload" />
				);
			}}
		</MediaImage>
	);
};
