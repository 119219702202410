import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl-next';

import Button from '@atlaskit/button/standard-button';
import EmptyState from '@atlaskit/empty-state';
import Image from '@atlaskit/image';

import { ErrorStateSubheading, ErrorStateContainer } from './errorStateStyles';

type ErrorStateProps = {
	cardId: string;
	heading: Record<string, React.ReactNode>;
	description: Record<string, React.ReactNode>;
	primaryAction?: Record<string, React.ReactNode>;
	image: string;
	cardHeight: number;
	link?: string;
	isInViewMode: boolean;
};

export const ErrorState = ({
	cardId,
	heading,
	description,
	primaryAction,
	image,
	cardHeight,
	link,
	isInViewMode,
}: ErrorStateProps) => {
	const intl = useIntl();

	return (
		<ErrorStateContainer id={cardId} cardHeight={cardHeight} isInViewMode={isInViewMode}>
			<EmptyState
				header={intl.formatMessage(heading)}
				description={
					<ErrorStateSubheading>
						<FormattedMessage {...description} />
					</ErrorStateSubheading>
				}
				primaryAction={
					!!primaryAction && (
						<Button href={link} target="_blank">
							<FormattedMessage {...primaryAction} />
						</Button>
					)
				}
				renderImage={() => <Image src={image} />}
			/>
		</ErrorStateContainer>
	);
};
