import { useRouteDataRef } from '@confluence/route-manager/entry-points/RouteState';

export const FALLBACK_URL = 'https://www.atlassian.com/';

export const useSmartCardFallbackUrl = () => {
	const routeDataRef = useRouteDataRef();

	const baseUrl = window.location.origin;

	return !!routeDataRef.current?.href ? `${baseUrl}${routeDataRef.current.href}` : FALLBACK_URL;
};
