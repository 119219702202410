import { fg } from '@confluence/feature-gating';

import { isUnsplashImage } from '../../../shared-components/UnsplashSearch/unsplashAPI';
import type { MediaConfigType } from '../../linkCardsTypes';

type getImageTypeToShowProps = {
	imageSrc?: string;
	mediaConfig?: MediaConfigType;
};

export const getImageTypeToShow = ({ imageSrc, mediaConfig }: getImageTypeToShowProps) => {
	const showUnsplashImage = isUnsplashImage(imageSrc);
	const showCustomUploadedImage = mediaConfig && !!imageSrc && !isUnsplashImage(imageSrc);

	return {
		showUnsplashImage,
		showCustomUploadedImage,
		hasNoImage:
			!showUnsplashImage && !showCustomUploadedImage && fg('company_hub_manual_cards_refinement'),
	};
};
