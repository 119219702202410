import { useEffect, useState } from 'react';

import {
	createEditorMediaProvider,
	createRendererMediaProvider,
} from '@confluence/fabric-media-support';

import type { MediaConfigType } from '../linkCardsTypes';

type useCardsMediaConfigProps = {
	isInViewMode?: boolean;
	contentId: string;
};

type UseCardsMediaConfigType = {
	mediaConfig: MediaConfigType;
};

export const useCardsMediaConfig = ({
	isInViewMode,
	contentId,
}: useCardsMediaConfigProps): UseCardsMediaConfigType => {
	const [mediaConfig, setMediaConfig] = useState<MediaConfigType>(null);
	useEffect(() => {
		if (!isInViewMode) {
			const createEditorMediaConfig = async () => {
				try {
					const { uploadParams, uploadMediaClientConfig } = await createEditorMediaProvider({
						contentId,
						contentType: 'page',
						isExternalShare: false,
						onUploadRejection: undefined,
					});
					if (uploadMediaClientConfig == undefined) {
						return null;
					}
					setMediaConfig({
						collection: uploadParams?.collection,
						authProvider: uploadMediaClientConfig.authProvider,
						onUploadRejection: uploadParams?.onUploadRejection,
					});
				} catch (e) {
					// TODO error handling https://hello.jira.atlassian.cloud/browse/CTE-3254
				}
			};
			void createEditorMediaConfig();
		} else if (isInViewMode) {
			const createRendererMediaConfig = async () => {
				try {
					const { viewMediaClientConfig } = await createRendererMediaProvider({
						contentId,
						isExternalShare: false,
					});

					setMediaConfig({
						collection: `contentId-${contentId}`,
						authProvider: viewMediaClientConfig.authProvider,
						onUploadRejection: undefined,
					});
				} catch (e) {
					// TODO error handling https://hello.jira.atlassian.cloud/browse/CTE-3254
				}
			};
			void createRendererMediaConfig();
		}
	}, [contentId, isInViewMode, setMediaConfig]);

	return { mediaConfig };
};
