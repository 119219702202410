import React from 'react';
import type { FC } from 'react';

import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';

import type { CardSizes } from '../../../linkCardsTypes';

import { RestrictedContent } from './RestrictedContent';
import { NoResults } from './NoResults';
import type { HeroErrorStateProps } from './HeroErrorState';

export type CardErrorStateAnalyticsProps = {
	createAnalyticsEvent?: CreateUIAnalyticsEvent;
	analyticsSource: string;
	extensionType: string;
};

export type CommonCardErrorStateProps = {
	cardId: string;
	cardSize: CardSizes;
	cardHeight: number;
	isInViewMode: boolean;
	isCardSelected?: boolean;
	isExtraSmallCard?: boolean;
	errorStatus: string;
	ariaLabel?: string;
} & Pick<HeroErrorStateProps, 'isVerticalHeroCard' | 'handleWidthObserverSetWidth'>;

export type CardErrorStateProps = {
	isRestricted: boolean;
	isNoResult: boolean;
	link?: string;
} & CommonCardErrorStateProps &
	CardErrorStateAnalyticsProps;

export const CardErrorState: FC<CardErrorStateProps> = ({
	link,
	isRestricted,
	isNoResult,
	...erroredStatePassThroughProps
}) => {
	if (isRestricted) return <RestrictedContent link={link} {...erroredStatePassThroughProps} />;

	if (isNoResult) return <NoResults {...erroredStatePassThroughProps} />;

	return null;
};
