import React, { useEffect } from 'react';
import { defineMessages } from 'react-intl-next';

import { CardSizes } from '../../../linkCardsTypes';
import noResultsImage from '../../../../assets/NoResults.svg';

import { SmallErrorState } from './SmallErrorState';
import { HeroErrorState } from './HeroErrorState';
import { ErrorState } from './ErrorState';
import type { CardErrorStateAnalyticsProps, CommonCardErrorStateProps } from './CardErrorState';

export type NoResultsErrorStateProps = CardErrorStateAnalyticsProps & CommonCardErrorStateProps;

export const i18n = defineMessages({
	noResults: {
		id: 'custom-sites-extensions.link-cards-content.card-components.error-states.no-results.header',
		defaultMessage: 'We can‘t show you this content',
		description:
			"Heading message shown when the URL we are trying to render as a smart card can't be found",
	},
	noResultsDescription: {
		id: 'custom-sites-extensions.link-cards-content.card-components.error-states.no-results.description',
		defaultMessage: 'The content doesn‘t exist or it may have changed after this link was added',
		description:
			"Describes possible action when URL we are trying to render as a smart card can't be found",
	},
});

export const NoResults = ({
	cardId,
	cardSize,
	cardHeight,
	isInViewMode,
	errorStatus,
	isCardSelected,
	isExtraSmallCard,
	createAnalyticsEvent,
	analyticsSource,
	extensionType,
	ariaLabel,
	isVerticalHeroCard,
	handleWidthObserverSetWidth,
}: NoResultsErrorStateProps) => {
	useEffect(() => {
		createAnalyticsEvent?.({
			type: 'sendOperationalEvent',
			data: {
				source: analyticsSource,
				action: 'error',
				actionSubject: 'noResult',
				attributes: {
					extensionType,
					error: errorStatus,
					isInViewMode,
				},
			},
		}).fire();
	}, [createAnalyticsEvent, errorStatus, isInViewMode, analyticsSource, extensionType]);

	switch (cardSize) {
		case CardSizes.EXTRA_SMALL:
		case CardSizes.SMALL:
			return (
				<SmallErrorState
					cardId={cardId}
					image={noResultsImage}
					heading={i18n.noResults}
					cardHeight={cardHeight}
					isInViewMode={isInViewMode}
					hideLink={cardSize === CardSizes.EXTRA_SMALL}
					isCardSelected={isCardSelected}
					isExtraSmallCard={isExtraSmallCard}
				/>
			);
		case CardSizes.HERO:
			return (
				<HeroErrorState
					cardId={cardId}
					image={noResultsImage}
					description={i18n.noResultsDescription}
					heading={i18n.noResults}
					cardHeight={cardHeight}
					isInViewMode={isInViewMode}
					ariaLabel={ariaLabel}
					isVerticalHeroCard={isVerticalHeroCard}
					handleWidthObserverSetWidth={handleWidthObserverSetWidth}
				/>
			);
		default:
			return (
				<ErrorState
					cardId={cardId}
					heading={i18n.noResults}
					description={i18n.noResultsDescription}
					image={noResultsImage}
					cardHeight={cardHeight}
					isInViewMode={isInViewMode}
				/>
			);
	}
};
