import type { FC } from 'react';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import {
	Card as SmartCard,
	ElementName,
	MediaPlacement,
	PreviewBlock,
	SmartLinkSize,
	SmartLinkTheme,
	TitleBlock,
	CustomBlock,
} from '@atlaskit/smart-card';
import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';

import type { Card as CardParams, MediaConfigType } from '../../linkCardsTypes';
import { CardSizes } from '../../linkCardsTypes';
import type { OnCardFailed, OnCardSucceeded } from '../useCardsCombinedExperienceState';
import { useTrackedSmartCardState } from '../useTrackedSmartCardState';
import { EmojiRenderer } from '../EmojiRenderer';
import { useSmartCardEmoji } from '../useSmartCardEmoji';
import { cardsDimensions } from '../GridCardWrapper';

import { CardErrorState } from './ErrorStates';
import {
	CommonCarouselWrapperStyles,
	getTitleBlockStyles,
	ExtraSmallCardWrapper,
	getCustomImageUploadStyles,
	extraSmallCardEmojiStyles,
} from './cardComponentsStyles';
import { CustomImageUpload } from './CustomImageUpload';
import { getImageTypeToShow } from './getImageTypeToShow';
import { useSmartCardFallbackUrl } from './useSmartCardFallbackUrl';

const i18n = defineMessages({
	emptyStatePlaceholderTitle: {
		id: 'custom-sites-extensions.link-cards.smart-card.extra-small-card.placeholder-title',
		defaultMessage: 'Add a title',
		description: 'Placeholder title that is displayed in Smart Card when no title is provided',
	},
});

type ExtraSmallCardProps = CardParams & {
	cardHeight: number;
	isAvatarShown?: boolean;
	isPublishDateShown?: boolean;
	isInViewMode: boolean;
	onCardSucceeded: OnCardSucceeded;
	onCardFailed: OnCardFailed;
	mediaConfig?: MediaConfigType;
	isEmptyCard?: boolean;
	showEmoji?: boolean;
	createAnalyticsEvent?: CreateUIAnalyticsEvent;
	isCardSelected?: boolean;
	analyticsSource: string;
	extensionType: string;
};

export const ExtraSmallCard: FC<ExtraSmallCardProps> = ({
	cardId,
	link,
	title,
	imageSrc,
	isAvatarShown,
	isPublishDateShown,
	cardHeight,
	isInViewMode,
	onCardSucceeded,
	onCardFailed,
	mediaConfig,
	isEmptyCard,
	showEmoji,
	createAnalyticsEvent,
	isCardSelected,
	analyticsSource,
	extensionType,
}) => {
	const {
		errorCardStatus,
		isNoResult,
		isRestricted,
		isUnauthorized,
		trackAndCategorizeError,
		trackSuccess,
	} = useTrackedSmartCardState(cardId, link, onCardSucceeded, onCardFailed);
	const intl = useIntl();
	const { emojiId } = useSmartCardEmoji(link);

	const fallBackUrl = useSmartCardFallbackUrl();

	const { showUnsplashImage, showCustomUploadedImage } = getImageTypeToShow({
		imageSrc,
		mediaConfig,
	});

	if (isRestricted || isNoResult)
		return (
			<CardErrorState
				isRestricted={isRestricted}
				isNoResult={isNoResult}
				link={link}
				cardId={cardId}
				cardSize={CardSizes.EXTRA_SMALL}
				cardHeight={cardHeight}
				isInViewMode={isInViewMode}
				errorStatus={errorCardStatus}
				createAnalyticsEvent={createAnalyticsEvent}
				isCardSelected={isCardSelected}
				isExtraSmallCard
				analyticsSource={analyticsSource}
				extensionType={extensionType}
			/>
		);

	return (
		<CommonCarouselWrapperStyles isCardSelected={isCardSelected}>
			<ExtraSmallCardWrapper
				minWidth={cardsDimensions[CardSizes.EXTRA_SMALL].minWidth}
				maxWidth={cardsDimensions[CardSizes.EXTRA_SMALL].maxWidth}
				cardHeight={cardHeight}
				isPublishDateShown={isPublishDateShown}
				hasEmoji
				isEmptyCard={isEmptyCard}
			>
				<SmartCard
					id={cardId}
					appearance="inline"
					frameStyle="show"
					platform="web"
					ui={{
						theme: SmartLinkTheme.Black,
						clickableContainer: false,
						size: SmartLinkSize.Large,
					}}
					url={link || fallBackUrl}
					onResolve={trackSuccess}
					onError={trackAndCategorizeError}
				>
					{showUnsplashImage && (
						<PreviewBlock
							ignoreContainerPadding
							placement={MediaPlacement.Left}
							overrideUrl={imageSrc}
						/>
					)}
					{showCustomUploadedImage ? (
						<CustomBlock overrideCss={getCustomImageUploadStyles(MediaPlacement.Left)}>
							<CustomImageUpload imageSrc={imageSrc} mediaConfig={mediaConfig} />
						</CustomBlock>
					) : null}
					{showEmoji && (
						<CustomBlock
							overrideCss={extraSmallCardEmojiStyles}
							testId="smart-block-link-card-emoji-container"
						>
							<EmojiRenderer emojiId={emojiId} height={24} />
						</CustomBlock>
					)}
					<TitleBlock
						hideIcon
						text={
							// a link with no title should expect undefined
							isEmptyCard ? title || intl.formatMessage(i18n.emptyStatePlaceholderTitle) : title
						}
						maxLines={2}
						anchorTarget="_self"
						overrideCss={getTitleBlockStyles({
							isExtraSmallCard: true,
							isEmptyCard,
							isDisabledTextColor: isEmptyCard ? !title : false,
							disablePointerEvents: true,
							isUnauthorized,
						})}
						metadata={!!isAvatarShown ? [{ name: ElementName.AuthorGroup }] : []}
						subtitle={!!isPublishDateShown ? [{ name: ElementName.ModifiedOn }] : []}
					/>
				</SmartCard>
			</ExtraSmallCardWrapper>
		</CommonCarouselWrapperStyles>
	);
};
