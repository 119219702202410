import { useCallback, useEffect, useState, useRef } from 'react';

import type { CardType as CardStatusType } from '@atlaskit/smart-card';

import { getMonitoringClient } from '@confluence/monitoring';
import { Attribution } from '@confluence/error-boundary';

import type { OnCardSucceeded, OnCardFailed } from './useCardsCombinedExperienceState';

type OnSmartCardErrorCallback = (data: {
	status: CardStatusType;
	url: string;
	err?: Error;
}) => void;

type UseTrackedSmartCardStateType = (
	cardId: string,
	link: string,
	onCardSucceeded: OnCardSucceeded,
	OnCardFailed: OnCardFailed,
) => {
	isNoResult: boolean;
	isRestricted: boolean;
	isUnauthorized: boolean;
	errorCardStatus: CardStatusType | '';
	trackAndCategorizeError: OnSmartCardErrorCallback;
	trackSuccess: () => void;
};
export const useTrackedSmartCardState: UseTrackedSmartCardStateType = (
	cardId,
	link,
	onCardSucceeded,
	onCardFailed,
) => {
	const [errorCardStatus, setErrorCardStatus] = useState<CardStatusType | ''>('');
	const shouldPreserveErrorStatus = useRef(false);
	// Other error types [`errored`, `fallback`] should not be caught. We will let the card UI render, even though
	// the SmartCard cannot fetch data for them
	const isRestricted = errorCardStatus === 'forbidden';
	const isUnauthorized = errorCardStatus === 'unauthorized';
	const isNoResult = errorCardStatus === 'not_found';

	useEffect(() => {
		setErrorCardStatus('');
	}, [link, setErrorCardStatus]);

	const failExperienceAndSendErrorMonitoring = useCallback(
		(err: Error) => {
			onCardFailed(err);
			getMonitoringClient().submitError(err, {
				attribution: Attribution.COMPANY_HUB,
			});
		},
		[onCardFailed],
	);

	const trackAndCategorizeError: OnSmartCardErrorCallback = useCallback(
		({ status, err }) => {
			setErrorCardStatus(status);
			if (status === 'unauthorized') {
				shouldPreserveErrorStatus.current = true;
			}
			if (err) {
				failExperienceAndSendErrorMonitoring(err);
			} else {
				onCardSucceeded(cardId);
			}
		},
		[setErrorCardStatus, cardId, onCardSucceeded, failExperienceAndSendErrorMonitoring],
	);

	const trackSuccess = useCallback(() => {
		onCardSucceeded(cardId);
		// For Smart Cards with an unauthorized state, it calls onResolve, but we don't want to clear the error status.
		// shouldPreserveErrorStatus is used b/c we need to skip clearing the error status for one cycle
		if (shouldPreserveErrorStatus.current) {
			shouldPreserveErrorStatus.current = false;
			return;
		}
		setErrorCardStatus('');
	}, [setErrorCardStatus, cardId, onCardSucceeded]);

	return {
		isNoResult,
		isRestricted,
		isUnauthorized,
		errorCardStatus,
		trackAndCategorizeError,
		trackSuccess,
	};
};
